<template>
<div>
   
  <div class="card" v-if="calls">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcı Görüşmeleri</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> Görüşme</span>
      </h3>
    </div>
    <!--end::Header-->

    
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive" v-if="calls">
              
 <div class="modal" tabindex="-1" id="call_detail">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Görüşme Detayları</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <div v-if="callDetailList">
            
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr class="fw-bolder">
                  <th>Durum</th>
                  <th>Başlangıç</th>
                  <th>Bitiş Tarihi	</th>
                  <th>Görüşme Süresi</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item,i) in callDetailList" :key="i">
                  <td>{{callDetailStatusses[item.status]}}</td>
                  <td>{{moment(item.startDate).format('DD MMMM YYYY HH:mm:ss')}}</td>
                  <td>{{moment(item.endDate).format('DD MMMM YYYY HH:mm:ss')}}</td>
                  <td>{{item.duration}}</td>
                </tr>
              </tbody>

              
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>

              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Danışman</th>                    
                    <th>Görüşme Türü</th>
                    <th>Görüşme Tarihi</th>
                    <th>Durum</th>
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in calls" :key="i">
                   
                    
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="d-flex justify-content-start flex-column">
                                <a :href="'consultant/'+item.doctor.userId" target="_blank" class="text-dark fw-bolder text-hover-primary fs-6"> {{item.doctor.name+ ' '+item.doctor.surname}}
                                    <span :class="'badge badge-light-'+personStatusses.class[item.doctor.status]">{{personStatusses.label[item.doctor.status]}}</span>
                                </a>
                                <span class="text-muted fw-bold text-muted d-block fs-7">
                                    <div class="d-flex align-items-center">
                                        <span>{{item.doctor.consultantType}}</span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </td>
                    <td>
                        {{ticketTypes[item.ticketTypeId]}}
                    </td>

                    <td>
                        <div class="d-flex flex-column">
                            <span class="text-dark fw-bolder">
                                {{moment(item.createdDate).format('DD MMMM YYYY HH:mm')}}
                            </span>
                            <span class="text-muted fw-bold text-muted d-block fs-7">
                                ({{duration(item.callTime)}})
                            </span>
                         </div>
                    </td>

                    <td>
                      
                      
                        <span :class="'badge badge-light-'+callStatusses[item.status].class">{{callStatusses[item.status].label}}</span>
                    </td>

                    <td>
                      <a class="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#call_detail" v-if="item.detailFlag" @click.prevent="detail(item)">Detaylar</a>
                      
                      
                      </td>
                    
                    </tr>            
                </tbody>
              </table>
  
               <paging 
                v-if="recordcount>0" 
                :page="page" 
                :pagelength="pagelength" 
                :recordcount="recordcount" 
                @next="next" 
                @prev="prev"
                @gotoPage="gotoPage($event)"
                />
      
            </div>
    </div>
  </div>

   
    
</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels.js"

import moment from 'moment'
import api from '@/core/services/ApiService'
import Paging from '@/components/BD/Paging.vue';
export default {
  components: { Paging },
  name: "Gorusmeler",
  props:['profile'],
  data(){
      return {
          calls:null,
          callDetailList:null,
          callDetailStatusses:{
            'CO': 'Bağlandı',
            'DC': 'Bağlantı Koptu',
            'CL': 'Bağlantı Kapatıldı',
            'DA': 'Tamamlandı'
          },
          recordcount:0,
          callTypes:labels.callTypes,
          ticketTypes:{
            0:'Tümü',
            1: 'Hemen Görüşme',
            10: 'Randevulu Görüşme'
        },
          personStatusses : {
            class:{
              'AC':'success',
              'DA':'danger'
            },
            label:{
              'AC':'Aktif',
              'DA':'Pasif'
            },
          },
          callStatusses:{
            'JJ':{label:'Tümü',class:'primary'},
            'PE':{label:'Bekleyen',class:'danger'},
            'AC':{label:'Aktif',class:'warning'},
            'CA':{label:'İptal',class:'danger'},
            'DA':{label:'Tamamlandı',class:'success'},
            'FA':{label:'Hata',class:'danger'},

          },
          pagelength:30,
          page:1,
      }
  },
  watch:{
      page:function(){
        this.getCalls();
      }
    },

  methods:{

    detail(item){
      api.get('VCalls/GetTicketDetail/'+item.ticket).then((res)=>{
        this.callDetailList = res.data;
      });
      //'http://90.159.30.197:9003/53b089e2-2738-ed11-8261-0050569fc5c2'
    }
    ,

    duration(time){
          let p = time.split(":");
          let hours = parseInt(p[0]);
          let minutes = parseInt(p[1]);
          let seconds = parseInt(p[2]);

           return (hours > 0 ? hours + " sa. " : '') + (minutes > 0 ? minutes + " dk. " :'')  + (seconds > 0 ? seconds + " sn." :'');
      },

      moment(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      getCalls(){
         if(this.profile){
           let callType = 0;
           let callStatus = 'JJ';

           let url = '/VCalls/ListUserTickets/'+callType+'/'+callStatus+'/'+this.profile.userId+'/'+this.pagelength+'/'+this.page;

             api.get(url).then((res)=>{
                this.calls = res.data.tickets;
                this.recordcount = res.data.rowCount;
            }).catch((err)=>{
              console.log(err);
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Görüşmeler", ["Kullanıcılar"]);
      this.getCalls();
    
  }
  
}
</script>
